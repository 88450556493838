
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'PaymentResult',

    data(): {
        isWarningClosed: boolean
    } {
        return {
            isWarningClosed: false,
        }
    },

    computed: {
        isWarningVisible(): boolean
        {
            return (this.$route.query.success !== undefined
                || this.$route.query.error !== undefined)
                && !this.isWarningClosed
        },
    },
})
