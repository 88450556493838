import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-15f13ac0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "items" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "item hull_1",
      src: `/img/items/hull/bo/warrior/side.png`
    }, null, 8, _hoisted_2),
    _createElementVNode("img", {
      class: "item probe_1",
      src: `/img/items/probe/3.png`
    }, null, 8, _hoisted_3),
    _createElementVNode("img", {
      class: "item droid_1",
      src: `/img/items/droid/6.png`
    }, null, 8, _hoisted_4)
  ]))
}