import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-69bec29b"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "spaceship noselect",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.enabled = !_ctx.enabled)),
    style: _normalizeStyle({
            width: `${_ctx.shipSize}px`,
            height: `${_ctx.shipSize}px`,
            left: `${_ctx.x - _ctx.shipSize/2}px`,
            top: `${_ctx.y - _ctx.shipSize/2}px`,
        })
  }, [
    _createElementVNode("img", {
      alt: "spaceship",
      draggable: "false",
      src: `/img/items/hull/bo/scout/top.png`,
      style: _normalizeStyle({
                transform: `rotate(${_ctx.rotation}rad)`,
            })
    }, null, 12, _hoisted_1)
  ], 4))
}