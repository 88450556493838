import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7c3a8c4c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.frame === null)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "box withoutFrame",
        style: _normalizeStyle([
            {
                minWidth: `${_ctx.size}px`,
                width: `${_ctx.size}px`,
                height: `${_ctx.size}px`
            },
        ])
      }, [
        _createElementVNode("img", {
          class: "face",
          src: `/img/page/landing/registration/avatar/${_ctx.face}/face.png`
        }, null, 8, _hoisted_1)
      ], 4))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "box withFrame",
        style: _normalizeStyle([
            {
                minWidth: `${_ctx.size}px`,
                width: `${_ctx.size}px`,
                height: `${_ctx.size}px`
            },
        ])
      }, [
        _createElementVNode("img", {
          class: "face",
          src: `/img/page/landing/registration/avatar/${_ctx.face}/face.png`
        }, null, 8, _hoisted_2),
        (_ctx.frame)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "frame",
              src: `/img/faceframes/${_ctx.frame}`
            }, null, 8, _hoisted_3))
          : _createCommentVNode("", true)
      ], 4))
}