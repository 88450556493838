import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-64dcbc86"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "bgs",
  ref: "bgs",
  class: "noselect"
}
const _hoisted_2 = ["data-depth"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image, i) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(["bgImage", {
                blur: image.blur,
                dynamicBlur: image.dynamicBlur !== 0 && _ctx.tick % image.dynamicBlur === 0,
            }]),
        key: i,
        style: _normalizeStyle({
              backgroundImage: `url('/img/page/landing/bg/${image.image}.webp')`,
              opacity: image.opacity,
            }),
        "data-depth": image.depth
      }, null, 14, _hoisted_2))
    }), 128))
  ], 512))
}