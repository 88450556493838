
import {defineComponent} from "vue";

export default defineComponent({
    name: 'LoadingSpin',

    props: {
        size: {
            type: Number,
            required: false,
            default: 30,
        },

        centerAligned: {
            type: Boolean,
            required: false,
            default: true,
        },

        margin: {
            type: Number,
            required: false,
            default: 15,
        },
    }
})
