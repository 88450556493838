
import {defineComponent} from "vue";
import Parallax from "parallax-js";
import store from "@/store";

export default defineComponent({
    data(): {
        parallaxInstance: Parallax | null,
        tick: number,
    } {
        return {
            parallaxInstance: null,
            tick: 0,
        }
    },

    props: {
        images: {
            type: Array,
            required: true
        }
    },

    mounted(): void
    {
        setInterval(() => {
            this.tick++
        }, 1000)

        const scene = this.$refs.bgs
        if (!(scene instanceof HTMLElement)) {
            return
        }

        this.parallaxInstance = new Parallax(scene)
        if (this.pageWidth <= store.state.mobileVersionThresholdWidth) {
            this.parallaxInstance.disable()
        }
    },

    computed: {
        pageWidth(): number
        {
            return store.state.pageSize.width
        },
    },

    watch: {
        pageWidth: {
            handler(newValue, oldValue): void
            {
                if (newValue <= store.state.mobileVersionThresholdWidth && oldValue > store.state.mobileVersionThresholdWidth) {
                    this.parallaxInstance?.disable()
                } else if (newValue > store.state.mobileVersionThresholdWidth && oldValue <= store.state.mobileVersionThresholdWidth) {
                    this.parallaxInstance?.enable()
                }
            },
        },
    },
})

