
import { defineComponent } from 'vue'

const __default__ = defineComponent({
    name: 'CardBackground',

    props: {
        tilt: {
            type: Object,
            required: true,
        },

        tiltAngle: {
            type: Number,
            required: true,
        },

        properties: {
            type: Object,
            required: true,
        },
    },

    computed: {
        computedTilt(): string
        {
            const x = 50 + 15 * (this.tilt.x / this.tiltAngle)
            const y = 50 - 25 * (this.tilt.y / this.tiltAngle)
            return `${x}% ${y}%`
        },
    },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "71f35d7d": (`url("/img/page/landing/registration/cardBg/${_ctx.properties.background}.webp")`),
  "a87a48ba": (_ctx.computedTilt)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__