import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import MainPage from '@/views/MainPage.vue'
import RegistrationPage from "@/views/RegistrationPage.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'main',
    component: MainPage,
    meta: { transition: 'slide-top' },
  },

  {
    path: '/register',
    name: 'registration',
    component: RegistrationPage,
    meta: { transition: 'slide-top' },
  },

  {
    path: '/register/:referralCode',
    name: 'register_with_referral_code',
    component: RegistrationPage,
    meta: { transition: 'slide-top' },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
