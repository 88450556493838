<script setup lang="ts">
</script>

<template>
    <div class="socialMediaIcons">
        <a target="_blank" href="https://vk.com/spacescouts" class="btn_social">
            <img alt="vk" :src="`/img/smi/vk.png`">
        </a>
        <a target="_blank" href="https://t.me/k_r_on" class="btn_social">
            <img alt="telegram" :src="`/img/smi/telegram.png`">
        </a>
        <a target="_blank" href="https://discord.gg/UTuxXqt2MW" class="btn_social">
            <img alt="discord" :src="`/img/smi/discord.png`">
        </a>
    </div>
</template>

<style scoped>
.socialMediaIcons {
    margin: 10px 0 10px 0;
    text-align: center;
}

.btn_social {
    width: 40px;
    height: 40px;
    display: inline-block;
    vertical-align: middle;
    border-radius: 30px;
    margin: 0 5px 0 5px;

    transition: transform 0.4s ease, scale 0.25s ease;
}

.btn_social img {
    width: 100%;
    height: 100%;
}

.btn_social:hover {
    scale: 1.2;
    transform: rotate(360deg);
}
</style>
