import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02871a4c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, {
    to: "/",
    class: "logoHolder"
  }, {
    default: _withCtx(() => [
      _createElementVNode("img", {
        class: "logo",
        alt: "Space Scouts, Космические Скауты",
        src: `/img/page/landing/logo.png`
      }, null, 8, _hoisted_1)
    ]),
    _: 1
  }))
}