
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'RegisterButton2',

    data() {
        return {
        }
    },

    mounted(): void
    {
        const button = this.$refs.registerButton

        if (button instanceof HTMLElement) {
            setTimeout(() => {
                button.classList.add('active')

                setInterval(() => {
                    button.classList.contains('maxOpen')
                        ? button.classList.remove('maxOpen')
                        : button.classList.add('maxOpen')
                }, 2000)
            }, 500)
        }
    }
})
