import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-76a94a3a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "frameBox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      style: _normalizeStyle({
                backgroundImage: `url('/img/frame1/top.png')`,
                top: `${-_ctx.offset}px`,
                left: `${(_ctx.corner.width * _ctx.sizeModifier) - _ctx.offset}px`,
                right: `${(_ctx.corner.width * _ctx.sizeModifier) - _ctx.offset}px`,
            }),
      class: "frame top horizontal"
    }, null, 4),
    _createElementVNode("div", {
      style: _normalizeStyle({
                backgroundImage: `url('/img/frame1/right.png')`,
                right: `${-_ctx.offset}px`,
                top: `${(_ctx.corner.height * _ctx.sizeModifier) - _ctx.offset}px`,
                bottom: `${(_ctx.corner.height * _ctx.sizeModifier) - _ctx.offset}px`,
            }),
      class: "frame right vertical"
    }, null, 4),
    _createElementVNode("div", {
      style: _normalizeStyle({
                backgroundImage: `url('/img/frame1/bottom.png')`,
                bottom: `${-_ctx.offset}px`,
                left: `${(_ctx.corner.width * _ctx.sizeModifier) - _ctx.offset}px`,
                right: `${(_ctx.corner.width * _ctx.sizeModifier) - _ctx.offset}px`,
            }),
      class: "frame bottom horizontal"
    }, null, 4),
    _createElementVNode("div", {
      style: _normalizeStyle({
                backgroundImage: `url('/img/frame1/left.png')`,
                left: `${-_ctx.offset}px`,
                top: `${(_ctx.corner.height * _ctx.sizeModifier) - _ctx.offset}px`,
                bottom: `${(_ctx.corner.height * _ctx.sizeModifier) - _ctx.offset}px`,
            }),
      class: "frame left vertical"
    }, null, 4),
    _createElementVNode("div", {
      style: _normalizeStyle({
                backgroundImage: `url('/img/frame1/top-right.png')`,
                top: `${-_ctx.offset}px`,
                right: `${-_ctx.offset}px`,
            }),
      class: "frame corner top-right"
    }, null, 4),
    _createElementVNode("div", {
      style: _normalizeStyle({
                backgroundImage: `url('/img/frame1/bottom-right.png')`,
                bottom: `${-_ctx.offset}px`,
                right: `${-_ctx.offset}px`,
            }),
      class: "frame corner bottom-right"
    }, null, 4),
    _createElementVNode("div", {
      style: _normalizeStyle({
                backgroundImage: `url('/img/frame1/bottom-left.png')`,
                bottom: `${-_ctx.offset}px`,
                left: `${-_ctx.offset}px`,
            }),
      class: "frame corner bottom-left"
    }, null, 4),
    _createElementVNode("div", {
      style: _normalizeStyle({
                backgroundImage: `url('/img/frame1/top-left.png')`,
                top: `${-_ctx.offset}px`,
                left: `${-_ctx.offset}px`,
            }),
      class: "frame corner top-left"
    }, null, 4)
  ]))
}