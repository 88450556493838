
import { defineComponent } from 'vue'

const __default__ = defineComponent({
    name: 'ThinFrame',

    data(): {
        corner: {
            width: number
            height: number
        }
    } {
        return {
            corner: {
                width: 31,
                height: 28,
            }
        }
    },

    props: {
        offset: {
            type: Number,
            required: true,
        }
    },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "76a5dbd8": (_ctx.corner.width + "px"),
  "ae3ba7d6": (_ctx.corner.height + "px")
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__