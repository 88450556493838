import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-613077bf"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  id: "bgFilter",
  ref: "bgFilter",
  class: "noselect"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (_ctx.show)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, null, 512))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}