
import {defineComponent} from "vue";

export default defineComponent({
    data(){
        return {
            show: true,
        }
    },

    mounted(): void
    {
        setTimeout(() => {
            this.show = false
        }, 10)
    },
})
