import { createStore } from 'vuex'
import {
    PassBonus,
    PassProperties
} from "@/components/RegistrationPage/Card/PassProperties";

class State
{
    canPurchase: boolean = true

    selectedPassKey: number | null = null

    passBonus: PassBonus | null = null

    mobileVersionThresholdWidth: number = 1280

    pageSize: {
        width: number
        height: number
    } = {
        width: 0,
        height: 0,
    }

    passes: PassProperties[] = []
    prices: {
        [newPassCode: string]: {
            buy: number,
            upgradeFrom: {
                [oldPassCode: string]: number,
            }
        }
    } = {}

    cursor: {
        x: number
        y: number
    } = {
        x: 0,
        y: 0,
    }
}

export default createStore({
    state: new State(),
    getters: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
    }
})
