class PassProperties
{
    name: string
    code: string
    level: number
    background: string
    secondaryBackground: string | null
    title: string
    glowColor: string
    labelColor: string
    avatar: Avatar
    frame: Frame
    ship: Ship
    container: Container
    isPlayersChoice: boolean
    basicPrice: number
    discountPrice: number | null
    galacoinAmount: number
    bonuses: PassBonus[]

    constructor(
        name: string,
        code: string,
        level: number,
        background: string,
        secondaryBackground: string | null,
        title: string,
        glowColor: string,
        labelColor: string,
        avatar: Avatar,
        frame: Frame,
        ship: Ship,
        container: Container,
        isPlayersChoice: boolean,
        basicPrice: number,
        discountPrice: number | null,
        galacoinAmount: number,
        bonuses: PassBonus[]
    ) {
        this.name = name
        this.code = code
        this.level = level
        this.background = background
        this.secondaryBackground = secondaryBackground
        this.title = title
        this.glowColor = glowColor
        this.labelColor = labelColor
        this.avatar = avatar
        this.frame = frame
        this.ship = ship
        this.container = container
        this.isPlayersChoice = isPlayersChoice
        this.basicPrice = basicPrice
        this.discountPrice = discountPrice
        this.galacoinAmount = galacoinAmount
        this.bonuses = bonuses
    }

    get price(): number
    {
        return this.discountPrice ?? this.basicPrice
    }
}

class PassBonus
{
    constructor(type: string, name: string, description: string, image: string) {
        this.type = type;
        this.name = name;
        this.description = description;
        this.image = image;
    }

    type: string
    name: string
    description: string
    image: string
}

class Avatar
{
    image: string
    name: string

    constructor(image: string, name: string) {
        this.image = image;
        this.name = name;
    }
}

class Frame
{
    image: string
    name: string

    constructor(image: string, name: string) {
        this.image = image;
        this.name = name;
    }
}

class Ship
{
    image: string
    name: string

    constructor(image: string, name: string) {
        this.image = image;
        this.name = name;
    }
}

class Container
{
    image: string
    name: string

    constructor(image: string, name: string) {
        this.image = image;
        this.name = name;
    }
}


interface PassApiDataInterface
{
    name: string
    code: string
    level: number
    background: string
    secondary_background: string | null
    glow_color: string
    label_color: string
    title: string
    avatar: ItemApiDataInterface
    frame: ItemApiDataInterface
    ship: ItemApiDataInterface
    container: ItemApiDataInterface
    basic_price: number
    discount_price: number | null
    players_choice: boolean
    galacoin_amount: number
    bonuses: BonusApiDataInterface[]
}

interface BonusApiDataInterface
{
    name: string
    image: string
    type: string
    description: string
}

interface ItemApiDataInterface
{
    name: string
    image: string
}

export {
    PassProperties,
    PassBonus,
    Avatar,
    Frame,
    Ship,
    Container,
    
    PassApiDataInterface,
    BonusApiDataInterface,
    ItemApiDataInterface,
}
