
import { defineComponent } from 'vue'
import store from "@/store"

class Point {
    x: number
    y: number

    constructor(x: number, y: number) {
        this.x = x
        this.y = y
    }
}

export default defineComponent({
    name: 'SpaceShip',

    data(): {
        shipSize: number
        enabled: boolean
        x: number
        y: number
        rotation: number
        speed: number
        turnSpeed: number

        interval: number | null
    } {
        return {
            shipSize: 100,

            enabled: false,

            x: -20,
            y: -20,
            rotation: Math.PI / 4,

            speed: 3,
            turnSpeed: 0.1,

            interval: null,
        }
    },

    mounted(): void
    {
        this.interval = setInterval(this.moveShip, 1000 / 60)
    },

    unmounted(): void
    {
        if (this.interval !== null) {
            clearInterval(this.interval)
        }
    },

    methods: {
        moveShip(): void
        {
            if (!this.enabled) {
                return
            }

            const angle = this.getAngleBetweenThreePoints(
                new Point(
                    this.x + 100 * Math.cos(this.rotation),
                    this.y + 100 * Math.sin(this.rotation),
                ),

                new Point(
                    this.x,
                    this.y,
                ),

                new Point(
                    store.state.cursor.x,
                    store.state.cursor.y,
                ),
            )

            this.rotation += (Math.abs(angle) <= this.turnSpeed)
                ? angle
                : (this.turnSpeed * (angle > 0 ? 1 : -1))

            this.x += this.speed * Math.cos(this.rotation)
            this.y += this.speed * Math.sin(this.rotation)
        },

        getAngleBetweenThreePoints(a: Point, o: Point, b: Point): number
        {
            const vOAx = -a.x + o.x
            const vOAy = -a.y + o.y

            const vOBx = -b.x + o.x
            const vOBy = -b.y + o.y

            const dotProduct = vOAx * vOBx + vOAy * vOBy
            const determinant = vOAx * vOBy - vOAy * vOBx

            return Math.atan2(determinant, dotProduct)
        }
    },
})
