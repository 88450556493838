
import {defineComponent, type PropType} from "vue";

export default defineComponent({
    name: 'FaceBox',

    props: {
        size: {
            type: Number,
            required: false,
            default: 100,
        },

        face: {
            type: String,
            required: true,
        },

        frame: {
            type: null as unknown as PropType<string | null>,
            required: false,
            default: null,
        },
    },
})
