
import { defineComponent } from 'vue'
import {PassProperties} from "@/components/RegistrationPage/Card/PassProperties";

export default defineComponent({
    name: 'CardInfo',

    props: {
        properties: {
            type: Object,
            required: true,
        },
        passKey: {
            type: Number,
            required: true,
        },
    },

    computed: {
        passProperties(): PassProperties
        {
            return this.properties as PassProperties
        },
    },
})
