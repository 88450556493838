
import { defineComponent } from 'vue'

const __default__ = defineComponent({
    name: 'TechnoFrame',

    data(): {
        corner: {
            width: number
            height: number
        },
        line: {
            width: number
            height: number
        },
    } {
        return {
            corner: {
                width: 350,
                height: 180,
            },

            line: {
                width: 64,
                height: 64,
            }
        }
    },

    props: {
        offset: {
            type: Number,
            required: true,
        },

        sizeModifier: {
            type: Number,
            default: 1,
        },
    },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "bc6e725e": ((_ctx.line.height * _ctx.sizeModifier) + "px"),
  "624a5046": ((_ctx.line.width * _ctx.sizeModifier) + "px"),
  "77e0bd85": ((_ctx.corner.width * _ctx.sizeModifier) + "px"),
  "3f000172": ((_ctx.corner.height * _ctx.sizeModifier) + "px")
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__