
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'PreorderInfo',

    data() {
        return {
        }
    },
})
